import { useForm } from 'react-hook-form';
import nookies from 'nookies';
import { useRouter } from 'next/router';

import Form from '../components/Form';
import InputWrapper from '../components/InputWrapper';
import Input from '../components/Input';
import Alert from '../components/Alert';
import Button from '../components/Button';
import Container from '../components/Container';
import InputLabel from '../components/InputLabel';
import FormActionsWrapper from '../components/FormActionsWrapper';

// hooks
import useFirebaseLogin from '../hooks/useFirebaseLogin';

import firebase from '../firebase';

export const getServerSideProps = async (context) => {
  try {
    const cookies = nookies.get(context);
    const user = await firebase.auth().verifyIdToken(cookies.token);

    if (user.uid) {
      return {
        redirect: {
          permanent: false,
          destination: '/admin',
        },
      };
    }
  } catch (error) {
    return {
      props: {},
    };
  }
};

const Login = () => {
  const { login, loading } = useFirebaseLogin();
  const router = useRouter();
  const redirect = router.query.redirect || '/';
  const form = useForm();

  const handleSubmit = async ({ email, password }) => {
    try {
      await login(email, password);
      router.push(redirect);
    } catch (error) {}
  };

  return (
    <div className='mt-24 '>
      <Container>
        <div className='max-w-xs mx-auto'>
          <Form onSubmit={handleSubmit} form={form}>
            <InputWrapper>
              <InputLabel>Email address</InputLabel>
              <Input type='text' name='email' required fullWidth />
            </InputWrapper>

            <InputWrapper>
              <InputLabel>Password</InputLabel>
              <Input type='password' name='password' required fullWidth />
            </InputWrapper>

            <FormActionsWrapper>
              <Button loading={loading} type='submit'>
                Sign in
              </Button>
            </FormActionsWrapper>
          </Form>
        </div>
      </Container>
    </div>
  );
};

export default Login;
